import * as cn from "classnames";
import { chain, defaultTo, get, reverse, size } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Button, { IconButton } from "react-toolbox/lib/button";
import Input from "react-toolbox/lib/input";
import BespokenDeleteIcon from "../../../assets/unicons/trash.svg";
import BespokenCopyIcon from "../../../assets/unicons/copy.svg";
import { setLoading } from "../../actions/loading";
import { fetchOrganization, fetchSources } from "../../actions/organization";
import { setCurrentPageProps } from "../../actions/context";
import { resetConfig, setSourceSearchText } from "../../actions/source";
import { BespokenPlatformsSelector } from "../../components/bespoken-linked-components";
import { AZSortingLink } from "../../components/lunacy/az-sorting/AZSortingLink";
import { ERROR, INFO } from "../../components/lunacy/debug-panel/DebugPanel";
import { WarningModal } from "../../components/Modal/WarningModal";
import { PlatformDropdown } from "../../components/lunacy";
import { PlatformIcon } from "../../components/PlatformIcons/PlatformIcons";
import { State } from "../../reducers";
import { OrganizationSource, SelectedOrganization } from "../../reducers/organization";
import { CurrentPageProps } from "../../reducers/context";
import { attemptInvoke, filterByProperty, wrapCallbackAsAsync } from "../../utils/ReactHelpers";
import * as Intercom from "../../services/intercom";
import { fetchInternalApi } from "../../services/internal-api";
import TestApi from "../../services/test-api";
import ImportModal from "./import-modal";
const globalWindow: any = typeof (window) !== "undefined" ? window : {};
import Snackbar from "react-toolbox/lib/snackbar";
import LottieAnimation from "../../utils/LottieAnimation";
import * as monitoringAnimation from "../../../assets/lottie-animations/heart-rate.json"

const Styles = require("./styles.scss");

interface DispatchToProps {
    fetchSources: () => Promise<void>;
    fetchOrganization: () => Promise<void>;
    setLoading: (value: boolean) => void;
    setCurrentPageProps?: (value: CurrentPageProps) => any;
    goTo?: (path: string) => void;
    resetConfig?: () => void;
    setSourceSearchText: (value: string) => any;
    sources: OrganizationSource[];
    organizationId: string;
    currentOrganization: SelectedOrganization;
}
function mapDispatchToProps(dispatch: any) {
    return {
        fetchSources: async () => wrapCallbackAsAsync(handle => dispatch(fetchSources("current", handle))),
        fetchOrganization: async () => wrapCallbackAsAsync(handle => dispatch(fetchOrganization("current", handle))),
        setLoading: (value: boolean) => dispatch(setLoading(value)),
        setCurrentPageProps: (value: CurrentPageProps) => dispatch(setCurrentPageProps(value)),
        setSourceSearchText: (value: string) => dispatch(setSourceSearchText(value)),
        goTo: (path: string) => dispatch(push(path)),
        resetConfig: () => dispatch(resetConfig()),
    };
}

interface StateToProps {
    sourceSearchText: string;
}
function mapStateToProps(state: State.All) {
    return {
        sources: state.organization?.selectedOrganization?.sources,
        sourceSearchText: state.source.searchText,
        organizationId: state.organization?.selectedOrganization?.id,
        currentOrganization: defaultTo(state?.organization?.selectedOrganization, {})
    };
}

interface ExposedProps { }
function mergeProps(ownProps: any, stateProps: any, dispatchProps: ExposedProps) {
    return { ...ownProps, ...stateProps, ...dispatchProps }
}

interface PageProps extends DispatchToProps, StateToProps { }

interface PageState {
    itemToDelete: ComfirmDeleteModalItem;
    showDeleteDialog: boolean;
    showNewCard: boolean;
    showImportModal: boolean;
    sortingDirection: "AZ" | "ZA";
    filteredPlatform: string;
    snackbarActive: boolean;
    snackbarLabel: string;
}

class SourcesPageComponent extends React.Component<PageProps, PageState> {

    static defaultProps: PageProps = {
        fetchSources: async () => { },
        fetchOrganization: async () => { },
        setLoading: () => { },
        setSourceSearchText: () => { },
        sources: [],
        sourceSearchText: '',
        organizationId: '',
        currentOrganization: undefined
    }

    constructor(props: PageProps) {
        super(props);
        this.state = {
            itemToDelete: undefined,
            showDeleteDialog: false,
            showNewCard: false,
            showImportModal: false,
            sortingDirection: "AZ",
            filteredPlatform: "",
            snackbarActive: false,
            snackbarLabel: ""
        }
    }

    componentWillReceiveProps(nextProps: Readonly<PageProps>, nextContext: any): void {
        if (nextProps.sourceSearchText !== this.props.sourceSearchText && nextProps.sourceSearchText.trim().length > 0) {
            console.info('source search text changed: ' + nextProps.sourceSearchText)
        }
        if (get(nextProps.currentOrganization, 'benefits.isSubscriptionExpired') === true) {
            this.props.goTo('/renewSubscription')
        }
    }

    async componentDidUpdate(prevProps: PageProps, prevState: PageState) { }

    async componentDidMount(): Promise<void> {
        this.props.setCurrentPageProps({ title: "Conversational", subTitle: "Test Suites" })
        try {
            this.props.setLoading(true)
            await this.props.fetchSources()
            // set a flag used to control when to force a logout
            localStorage.setItem('activeSession', 'true');
        } catch (err) {
            ERROR("Error fetching sources", err)
        }
        this.props.resetConfig()

        const numTestSuites = this.props.sources?.length || 0;
        // sorces are loaded from the beginning, maybe add to componentWillReceiveProps
        Intercom.updateIntercom(globalWindow, {
            numTestSuites,
            company: {
                id: this.props.organizationId,
                numTestSuites,
            }
        });

        this.props.setLoading(false)

    }

    componentWillUnmount() {
        // Reset the state of the source filter to be blank
        this.props.setSourceSearchText('')
    }

    handleImport = async (file: File) => {
        this.props.setLoading(true);
        let response
        try {
            // Process the importData here
            response = await TestApi.excelImport(file)

            // After processing, you might want to refetch the sources
            await this.props.fetchSources();
        } catch (err) {
            ERROR("Error importing data", err);
        } finally {
            this.setState({ showImportModal: false, snackbarActive: true, snackbarLabel: response });
            this.props.setLoading(false);
        }
    }

    handleShowSnackbar = (label?: any) => {
        this.setState(prevState => ({
            ...prevState,
            snackbarLabel: label,
            snackbarActive: true
        }));
    }

    handleSnackbarClick = () => {
        this.setState({ ...this.state, snackbarActive: false });
    }

    render(): false | JSX.Element {
        return (<div className={cn(Styles.container)}>
            <BespokenPlatformsSelector onChangeCategory={({ value: filteredPlatform }) => this.setState({ filteredPlatform })} />
            <AZSortingLink onChange={sortingDirection => this.setState({ sortingDirection })} />
            <div className={cn(Styles.separator)}>|</div>
            <ImportFromExcelLink onClick={() => this.setState({ showImportModal: true })} />
            <div className={cn(Styles.separator)}>|</div>
            <CreateSourceLink onCreateSource={() => this.setState({ showNewCard: true })} />
            <SourceList
                filteredPlatform={this.state.filteredPlatform}
                showNewCard={this.state.showNewCard}
                searchText={this.props.sourceSearchText}
                sortingDirection={this.state?.sortingDirection}
                onCreate={async (newSource) => {
                    this.props.setLoading(true)

                    try {
                        const { name, platform } = newSource
                        const payload = {
                            testingJson: { platform },
                            metadata: { name },
                            name,
                        }
                        const { id: sourceId } = await fetchInternalApi(({ userId, organizationId }) => `/users/${userId}/organizations/${organizationId}/testsuites`, 'POST', payload);
                        INFO('New source create with id: ', sourceId)

                        await this.props.fetchSources()
                        this.props.goTo(`skills/${sourceId}`)
                    } catch (err) {
                        console.error(err);
                    }

                }}
                onClone={async (itemToClone) => {
                    this.props.setLoading(true)

                    try {
                        const { id } = itemToClone
                        const { id: sourceId } = await fetchInternalApi(({ userId, organizationId }) => `/users/${userId}/organizations/${organizationId}/testsuites/${id}/clone`, 'POST', {});
                        INFO('New source create with id: ', sourceId)
                        await this.props.fetchSources()
                    } catch (err) {
                        console.error(err);
                    }
                    finally {
                        this.props.setLoading(false)
                    }

                }}
                onDelete={(itemToDelete: any) => this.setState({ itemToDelete, showDeleteDialog: true })}
                onOpen={(it: OpenSourceParam) => this.props.goTo(`skills/${it?.id}`)}
            />
            <ComfirmDeleteModal
                onOkDialog={async (deletingSource) => {
                    this.props.setLoading(true)

                    try {
                        const { id: sourceId, organizationId } = deletingSource
                        const ret = await fetchInternalApi(({ userId, organizationId }) => `/users/${userId}/organizations/${organizationId}/testsuites/${sourceId}`, 'DELETE');
                        INFO('Deleted source: ', sourceId)
                        console.log('fetch shources from onDelete')
                        await this.props.fetchSources()
                    } catch (err) {
                        console.error(err);
                    } finally {
                        this.setState({ showDeleteDialog: false, itemToDelete: undefined })
                        this.props.setLoading(false)
                    }

                }}
                onDismissDialog={() => this.setState({ showDeleteDialog: false, itemToDelete: undefined })}
                item={this.state.itemToDelete}
                showModal={this.state.showDeleteDialog}
            />
            <ImportModal
                showModal={this.state.showImportModal}
                onDismiss={() => this.setState({ showImportModal: false })}
                handleImport={this.handleImport}
            />
            <Snackbar
                className="sm-snackbar"
                action="Dismiss"
                type="cancel"
                active={this.state.snackbarActive}
                label={this.state.snackbarLabel}
                timeout={10000}
                onClick={this.handleSnackbarClick}
                onTimeout={this.handleSnackbarClick}
            />
        </div>)
    }
}

export const SourcesPage = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(SourcesPageComponent);


const SourceListStyles = require("./source-list-styles.scss");
type SourceListProps = {
    sources: OrganizationSource[];
    organizationId: string;
}
type NewSourceParam = { name: string; platform: string; organizationId: string; }
type OpenSourceParam = { name: string; id?: string, platform: string; organizationId: string; }
type DeleteSourceParam = { name: string; platform: string; organizationId: string; }
type CloneSourceParam = { id: string; organizationId: string; }
type SourceListExposedProps = {
    onCreate: (item: NewSourceParam) => void;
    onClone: (item: CloneSourceParam) => void;
    onDelete: (item: DeleteSourceParam) => void;
    onOpen: (item: OpenSourceParam) => void;
    showNewCard: boolean;
    searchText: string;
    sortingDirection: "AZ" | "ZA";
    filteredPlatform: string;
}
const SourceList = connect(
    function mapStateToProps(state: State.All) {
        const sources = state.organization?.selectedOrganization?.sources
        const organizationId = state.organization?.selectedOrganization?.id
        return {
            sources,
            organizationId
        };
    },
    undefined,
    function mergeProps(ownProps: any, stateProps: any, dispatchProps: SourceListExposedProps) {
        return { ...ownProps, ...stateProps, ...dispatchProps }
    }
)((props: SourceListProps & SourceListExposedProps) => {
    const { onCreate, onDelete, onClone, onOpen, showNewCard, sortingDirection, searchText, filteredPlatform, organizationId } = props
    return (
        <div className={SourceListStyles.container}>
            {
                chain(props.sources)
                    .sortBy(it => it.name.toLowerCase())
                    .thru(filterByProperty("config.platform", filteredPlatform))
                    .thru(sources => {
                        // Filter the sources shown, if the search bar is set
                        return sources.filter((s) => {
                            return (s.name.toLowerCase().includes(searchText.toLowerCase())
                            || s.id.toLowerCase().includes(searchText.toLowerCase()))
                        })
                    })
                    .tap(all => sortingDirection === 'ZA' ? reverse(all) : all)
                    .map(it => ({ ...it, organizationId }))
                    .map((it: OrganizationSource) =>
                        <Card
                            key={`key-${it?.id}`}
                            data={{ id: it?.id, name: defaultTo(it?.name, it?.meta?.name), platform: it?.config?.platform, organizationId: it.organizationId, monitoring: !!it.monitoringConfig }}
                            onDelete={() => attemptInvoke<DeleteSourceParam>(onDelete, it)}
                            onClone={() => attemptInvoke<CloneSourceParam>(onClone, it)}
                            onOpen={() => attemptInvoke<OpenSourceParam>(onOpen, it)}
                        />
                    )
                    .tap(its => its.push(<AddCard key={`key-new-source`} editMode={showNewCard} onCreate={it => attemptInvoke<NewSourceParam>(onCreate, it)} />))
                    .value()
            }
        </div>
    )
})



type CreateSourceLinkProps = { onCreateSource: () => void }
const CreateSourceLink = connect(
    undefined,
    undefined,
    function mergeProps(ownProps: any, stateProps: any, dispatchProps: CreateSourceLinkProps) {
        return { ...ownProps, ...stateProps, ...dispatchProps }
    }
)((props: CreateSourceLinkProps) => {
    const { onCreateSource } = props
    return <div><a style={{ userSelect: "none" }} onClick={() => attemptInvoke(onCreateSource)}>
        Add new
    </a></div>
})

type ImportFromExcelLinkProps = { onClick: () => void }
const ImportFromExcelLink = connect(
    undefined,
    undefined,
    function mergeProps(ownProps: any, stateProps: any, dispatchProps: ImportFromExcelLinkProps) {
        return { ...ownProps, ...stateProps, ...dispatchProps }
    }
)((props: ImportFromExcelLinkProps) => {
    const { onClick } = props
    return <div><a onClick={() => attemptInvoke(onClick)}>
        Import
    </a></div>
})


const CardStyles = require("./card-styles.scss");
type CardData = { platform: string; name: string; id: string; organizationId: string; monitoring: boolean };
type CardProps = {
    data: CardData;
    onDelete?: (it: CardData) => void;
    onClone?: (it: CardData) => void;
    onOpen?: (it: CardData) => void;
};

class Card extends React.Component<CardProps, { isHovered: boolean }> {
    constructor(props: CardProps) {
        super(props);
        this.state = {
            isHovered: false, // Initial hover state
        };
    }

    // Handlers for mouse events
    handleMouseEnter = () => {
        console.log("Mouse entered");
        this.setState({ isHovered: true });
    };

    handleMouseLeave = () => {
        console.log("Mouse leave");
        this.setState({ isHovered: false });
    };

    render() {
        const { onDelete, onClone, onOpen, data } = this.props;
        return (
            <div className={CardStyles.container}>
                <div
                    className={CardStyles.icon}
                    onClick={evt => { evt.stopPropagation(); return attemptInvoke(onOpen, data); }}
                >
                    <PlatformIcon platform={data?.platform} />
                </div>
                <div className={CardStyles.title} onClick={evt => { evt.stopPropagation(); return attemptInvoke(onOpen, data); }}>
                    <div className={CardStyles.platform}>{data?.platform}</div>
                    <div className={CardStyles.name} title={data?.name}>{data?.name}</div>
                </div>
                <div className={CardStyles.monitoring}>
                    {data?.monitoring && (
                        <div className={CardStyles.monitoringOn} title="Monitoring enabled.">
                                <LottieAnimation animationData={monitoringAnimation} width={24} height={24} />
                        </div>
                    )}
                </div>
                <div className={CardStyles.results}>
                    &nbsp;
                </div>
                <div className={CardStyles.actions}>
                    <div className={CardStyles.action} title="Delete">
                        <IconButton className={CardStyles.delete_button} icon={<BespokenDeleteIcon />}
                            onClick={(evt: Event) => { evt.stopPropagation(); return attemptInvoke<CardData>(onDelete, data); }} />
                    </div>
                    <div className={CardStyles.action} title="Clone">
                        <IconButton icon={<BespokenCopyIcon />}
                            onClick={(evt: Event) => { evt.stopPropagation(); return attemptInvoke<CardData>(onClone, data); }} />
                    </div>
                </div>
                <div className={CardStyles.open}>
                    <Button
                        data-id="source-selector-modal-delete-action"
                        data-intercom-target="TestButton"
                        theme={require("../../themes/bespoken_button.scss")}
                        accent={true}
                        onClick={(evt: Event) => { evt.stopPropagation(); return attemptInvoke<CardData>(onOpen, data); }}>Test</Button>
                </div>
            </div>
        );
    }
}

export default connect(
    undefined,
    undefined,
    function mergeProps(ownProps: any, stateProps: any, dispatchProps: CardProps) {
        return { ...ownProps, ...stateProps, ...dispatchProps };
    }
)(Card);

type ComfirmDeleteModalItem = { id: string, name: string, organizationId: string }
type ComfirmDeleteModalProps = { showModal: boolean, item: ComfirmDeleteModalItem, onOkDialog: (item: ComfirmDeleteModalItem) => void, onDismissDialog: () => void }
const ComfirmDeleteModal = (props: ComfirmDeleteModalProps) => {
    const { item, onDismissDialog, onOkDialog, showModal } = props
    const { name } = item || {}
    return (
        <WarningModal item={item} onDismissDialog={() => attemptInvoke(onDismissDialog)} onOkDialog={it => attemptInvoke(onOkDialog, it)} showModal={showModal}>
            <p>Are you sure you want to delete <strong>{name}</strong>? This cannot be undone. Past results will still be available on the History page.</p>
        </WarningModal>
    )
}


const AddCardStyles = require("./add-card-styles.scss");
type AddCardData = { platform: string; name: string; }
type AddCardExposedProps = { onCreate?: (it: AddCardData) => void; editMode: boolean; }
type AddCardStateToProps = { defaultPlatformId: string; }
type AddCardProps = AddCardExposedProps & AddCardStateToProps
type AddCardState = { editMode: boolean; name: string; platform: string; }
const AddCard = connect(
    function mapStateToProps(state: State.All): AddCardStateToProps {
        const defaultPlatformId = chain(state?.organization?.selectedOrganization?.subscription?.platforms)
            .uniq()
            .sort()
            .first()
            .value()

        return {
            defaultPlatformId
        };
    },
    undefined,
    function mergeProps(ownProps: AddCardProps, stateProps: AddCardStateToProps, dispatchProps: AddCardExposedProps) {
        return { ...ownProps, ...stateProps, ...dispatchProps }
    }
)(class extends React.Component<AddCardProps, AddCardState> {

    constructor(props: AddCardProps) {
        super(props);

        this.state = {
            editMode: false,
            name: '',
            platform: props.defaultPlatformId
        };
    }

    componentWillReceiveProps(nextProps: Readonly<AddCardProps>, nextContext: any): void {
        if (nextProps.editMode !== this.state.editMode) {
            this.setState({ editMode: nextProps.editMode })
        }
    }

    render(): false | JSX.Element {
        const { editMode } = this.state

        if (!editMode) {
            return (
                <div className={AddCardStyles.container_new} onClick={() => this.setState({ editMode: true, name: '', platform: this.props.defaultPlatformId })} data-intercom-target="NewSuite">
                    <IconButton
                        className={AddCardStyles.icon_button}
                        icon={"add"}
                    />
                    <div>Create a new test suite</div>
                </div>
            )
        }


        const { onCreate } = this.props
        const { name, platform } = this.state
        const disableCreateButton = size(name) < 4

        return <div className={AddCardStyles.container}>
            <div className={AddCardStyles.close}>
                <IconButton data-id="new-skill-close"
                    className={AddCardStyles.close_edit_button}
                    icon={"cancel"}
                    onClick={() => this.setState({ editMode: false })} />
            </div>
            <div className={AddCardStyles.icon}>
                <PlatformIcon platform={platform} />
            </div>
            <div className={AddCardStyles.title}>
                <div className={AddCardStyles.platform}>
                    <PlatformDropdown
                        size="small"
                        selectedValue={platform}
                        onValueChanged={({ value: platform }) => this.setState({ platform })}
                    />
                </div>
                <div className={AddCardStyles.name}>
                    <Input theme={require("../../themes/bespoken_input.scss")}
                        maxLength={50}
                        className={cn(require("../../themes/bespoken_input.scss").small)}
                        value={name}
                        onChange={(name: string) => this.setState({ name })}
                        onKeyUp={(evt: KeyboardEvent) => {
                            if (evt.key === 'Escape') { this.setState({ editMode: false }) }
                        }}
                        onKeyPress={(evt: KeyboardEvent) => {
                            if (disableCreateButton) { return }
                            if (evt.key === 'Enter') { attemptInvoke(onCreate, { name, platform }) }
                        }}

                        {...{ autoFocus: true }}
                    />
                </div>
            </div>
            <div className={AddCardStyles.results}>
                &nbsp;
            </div>
            <div className={AddCardStyles.open}>
                <Button
                    theme={require("../../themes/bespoken_button.scss")}
                    disabled={disableCreateButton}
                    onClick={(evt: Event) => { evt.stopPropagation(); this.setState({ editMode: false }); return attemptInvoke(onCreate, { name, platform }) }}>Create</Button>
            </div>
        </div>
    }
})