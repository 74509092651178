import * as React from "react";
import * as cn from "classnames";
const styles = require("./BetaBanner.scss");
import { fetchInternalApi } from "../../services/internal-api";
import { FirebaseAuth } from "../../services/new-auth";
import { setCrossDomainCookie, getCookie } from "../../utils/cookies";
import * as Firebase from "firebase";

interface BetaBannerProps {
    onDismiss: () => void;
}

interface BetaBannerState {
    isLoading: boolean;
    isDismissing: boolean;
}

export class BetaBanner extends React.Component<BetaBannerProps, BetaBannerState> {
    constructor(props: BetaBannerProps) {
        super(props);
        this.state = {
            isLoading: false,
            isDismissing: false
        };
    }

    handleBetaOptIn = async () => {
        this.setState({ isLoading: true });
        try {
            // Get the current user
            const auth = new FirebaseAuth();
            const userId = Firebase.auth().currentUser?.uid;

            if (!userId) {
                console.error('No user ID found');
                this.setState({ isLoading: false });
                return;
            }

            // Get the current user's properties
            const userProperties = await fetchInternalApi(`/users/${userId}`, "GET");

            if (!userProperties) {
                console.error('No user properties found');
                this.setState({ isLoading: false });
                return;
            }

            const jsonProp = userProperties.json || {};
            jsonProp.betaUser = true;

            // First, update the user property to mark them as a beta user
            await fetchInternalApi(`/users/${userId}/property/json`, "PATCH", {
                value: jsonProp
            });

            // Then get the custom token for cross-domain authentication
            const response = await fetchInternalApi(`/login/custom-token`, "POST", {},
                {
                    'Authorization': `Bearer ${await auth.getToken()}`,
                    'Content-Type': 'application/json'
                }, false);

            const customToken = response.customToken;

            // Redirect to beta with the token
            const betaUrl = process.env.BETA_URL || "https://dashboard.bespoken.ai/signin";
            window.location.href = `${betaUrl}?token=${encodeURIComponent(customToken)}`;
        } catch (error) {
            console.error('Error opting into beta:', error);
            this.setState({ isLoading: false });
        }
    };

    handleDismiss = () => {
        this.setState({ isDismissing: true });
        // Wait for animation to complete before actually removing
        setTimeout(() => {
          this.props.onDismiss();
        }, 200); // Match animation duration
    };

    render() {
        const { isLoading, isDismissing } = this.state;

        return (
            <div className={cn(styles.betaBanner, { [styles.dismissing]: isDismissing })}>
                <div className={styles.content}>
                    <h3>Try Our New Experience!</h3>
                    <p>We've rebuilt our dashboard with improved performance and new features.</p>
                </div>
                <div className={styles.actions}>
                    <button
                        className={styles.tryButton}
                        onClick={this.handleBetaOptIn}
                        disabled={isLoading}
                    >
                        {isLoading ? "Loading..." : "Try Beta"}
                    </button>
                    <button
                        className={styles.dismissButton}
                        onClick={this.handleDismiss}
                    >
                        Dismiss
                    </button>
                </div>
            </div>
        );
    }
}