import { includes, isEmpty, isFunction, startsWith, toLower, trim } from "lodash";
import { BespokenSessionArgs } from "../index-utils";
import firebase = require("firebase");
import YamlHelper from "../helpers/yaml-helper";
import { OrganizationSource, VirtualDevices } from "../reducers/organization";
import { Auth, FirebaseAuth } from "./new-auth";

export const INTERNAL_API_URL = process.env.INTERNAL_API_URL;

type HTTPMethod = "GET" | "POST" | "PUT" | "DELETE" | "HEAD" | "PATCH";
type SessionAttributes = { userId?: string, organizationId: string };
type UrlBuilder = (args: SessionAttributes) => string;

export async function fetchInternalApi(path: string | UrlBuilder,
    method: HTTPMethod = "GET",
    bodyObject: any = undefined,
    headers: any = { "Content-Type": "application/json" },
    addFirebaseTokenToHeader: boolean = true): Promise<any> {
    if (isFunction(path)) {
        path = path({ userId: BespokenSessionArgs.UserId, organizationId: BespokenSessionArgs.OrganizationId });
    }

    if (addFirebaseTokenToHeader) {
        const auth: Auth =  new FirebaseAuth();
        const idToken = await auth.getToken();
        if (!isEmpty(idToken)) {
            headers["firebase_id_token"] = idToken;
        }
    }


    const url = `${INTERNAL_API_URL}${path}`;
    const body = JSON.stringify(bodyObject);

    const response = await fetch(url, { method, body, headers });
    // When method is HEAD this returns true if status is 200 otherwise returns false
    if (method === "HEAD") {
        return response.status === 200;
    }

    let bodyParsed: any;
    const contentType = trim(response.headers.get("content-type"));
    if (startsWith(contentType, "application/json")) {
        bodyParsed = await response.json();
    } else if (startsWith(contentType, "application/zip")) {
        bodyParsed = await response.blob();
    } else {
        bodyParsed = await response.text();
    }
    if (response.ok) return bodyParsed;
    else throw bodyParsed;
}

type ReponseVirtualDevice = {
    token: string;
    origin: string;
    paramOverride: object;
    platform: string;
    status: string;
    trialExpires: string;
    organizationId: string;
    createdAt: string;
    createdBy?: string;
    hardLimits?: boolean;
    name: string;
    _dynamoDbItem?: object;
    _firebaseItem?: object;
};
export const virtualDeviceMapper = (res: ReponseVirtualDevice): VirtualDevices => {
    const { token, platform, name, status, createdAt } = res;
    return { token, platform, name, status, created: new Date(createdAt) };
};

type TestSuite = {
    id: string;
    organizationId: string;
    name: string;
    description: string | null;
    metadata: object;
    yaml: string;
    testingJson: object;
    createdAt: Date;
    lastModified: Date;
    yamlObject?: any;
    monitoringConfig?: any;
};
export const testSuiteMapper = (testSuite: TestSuite): OrganizationSource => {
    return ({
        name: testSuite?.name,
        meta: testSuite?.metadata,
        config: testSuite?.testingJson,
        yaml: testSuite?.yaml,
        organizationId: testSuite?.organizationId,
        id: testSuite?.id,
        yamlObject: YamlHelper.toYamlDashboardObject(testSuite?.yamlObject),
        monitoringConfig: testSuite?.monitoringConfig,
    } as unknown as OrganizationSource);
};