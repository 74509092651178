import BespokenAddIcon from "../../../../assets/bespoken_add_icon.svg";
import BespokenRearrengeIcon from "../../../../assets/bespoken_rearrange_icon.svg";
import Dropdown from "react-toolbox/lib/dropdown";
import { ErrorBadgeSmall, MatchViewer, PlusSignIconButton, RemoveIconButton, SuccessBadgeSmall } from '../../lunacy';
import { IconButton } from "react-toolbox/lib/button";
import { IconLabelButton } from "../../IconLabelButton/IconLabelButton";
import Input from "react-toolbox/lib/input";
import { OPERATORS } from "../../../constants/validation";
import { PropertyDropdown } from '../../lunacy/dropdowns/PropertyDropdown';
import React = require("react");
import ReactDiv from "../../ReactDiv";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { Source } from "../../../models/source";
import SpinnerImg from "../../../../assets/spinner.svg";
import { TestResultStatus } from "../../../constants";
import Tooltip from "react-toolbox/lib/tooltip";
import * as cn from 'classnames';
import { chain, isEmpty, isNull, isUndefined, map, trim, uniqueId, isEqual } from 'lodash';

const Styles = require("./test-editor.scss");

const validationVisualStyle = require("../../validation/ValidationVisualComponentStyle.scss");
const bespokenTooltipTheme = require("../../../themes/tooltip.scss");

export const TooltipDiv = Tooltip(ReactDiv);

const DISABLED_DUE_IS_NOT_IMPLEMENTED = false
const DragHandle = SortableHandle(() => <span><BespokenRearrengeIcon /></span>);

const SortableItem = SortableElement(({ grabbing, itemToRender, loadingResults, itemCount }: any) => <div className={`${validationVisualStyle.drag_sortable} ${grabbing ? validationVisualStyle.grabbing : ""}`}>
    {
        DISABLED_DUE_IS_NOT_IMPLEMENTED && !loadingResults && itemCount > 1 &&
        <DragHandle />
    }
    {itemToRender}
</div>);

const SortableParentContainer = SortableContainer(({ children }: any) => {
    return <div className={validationVisualStyle.sortable_list}>{children}</div>;
});

interface TestEditorProps {
    selectedTestIndex: number;
    source: Source;
    interimResults: {
        [testIndex: number]: string[];
    }
    testResults: any;
    runningTestIndexes: number[];
    addInteraction: (testIndex: number, interactionIndex?: number) => void;
    removeInteraction: (testIndex: number, interactionIndex: number) => void;
    updateInteractionInput: (testIndex: number, interactionIndex: number, input: string) => void;
    addAssertion: (testIndex: number, interactionIndex: number) => void;
    deleteAssertion: (testIndex: number, interactionIndex: number, itemIndex: number) => void;
    updateAssertionAction: (testIndex: number, interactionIndex: number, itemIndex: number, value: string) => void;
    updateAssertionOperator: (testIndex: number, interactionIndex: number, itemIndex: number, value: string) => void;
    updateAssertionValue: (testIndex: number, interactionIndex: number, itemIndex: number, value: string) => void;
}

interface TestEditorState {
    grabbing: boolean;
    utterClass: any;
    typedInterimResults: { [testIndex: number]: string[] };
    typingTimeouts: {
        [testIndex: number]: {
            [interactionIndex: number]: ReturnType<typeof setTimeout>
        }
    };
}

export default class TestEditor extends React.Component<TestEditorProps, TestEditorState> {

    constructor(props: TestEditorProps) {
        super(props);

        this.state = {
            grabbing: false,
            utterClass: {},
            typedInterimResults: [],
            typingTimeouts: {},
        };
    }

    shouldComponentUpdate(nextProps: TestEditorProps, nextState: TestEditorState) {
        // Check if the test has completed
        const currentTestCompleted = this.props.runningTestIndexes.length === 0;
        const nextTestCompleted = nextProps.runningTestIndexes.length === 0;

        // If the test has just completed, allow one more update
        if (currentTestCompleted !== nextTestCompleted) {
            return true;
        }

        // If the test is completed, only update if certain props or state have changed
        if (currentTestCompleted && nextTestCompleted) {
            return (
                this.props.selectedTestIndex !== nextProps.selectedTestIndex ||
                !isEqual(this.props.source, nextProps.source) ||
                !isEqual(this.props.testResults, nextProps.testResults) ||
                !isEqual(this.state.utterClass, nextState.utterClass) ||
                !isEqual(this.state.typedInterimResults, nextState.typedInterimResults)
            );
        }

        // For running tests, always update to ensure typing animation completes
        if (!currentTestCompleted || !nextTestCompleted) {
            return true;
        }

        // For other cases, compare props and state
        return (
            !isEqual(this.props, nextProps) ||
            !isEqual(this.state, nextState)
        );
    }

    componentDidUpdate(prevProps: TestEditorProps) {
        if (prevProps.selectedTestIndex !== this.props.selectedTestIndex) {
            this.clearTypedResultForTest(prevProps.selectedTestIndex);
        }

        if (prevProps.interimResults !== this.props.interimResults) {
            Object.entries(this.props.interimResults).forEach(([testIndex, results]) => {
                results.forEach((result: string, index: number) => {
                    this.typeInterimResult(parseInt(testIndex), index, result);
                });
            });
        }

        // Clear all typed results when starting a new test run
        if (prevProps.runningTestIndexes.length === 0 && this.props.runningTestIndexes.length > 0) {
            this.clearTypedResults();
        }

        // Clear typed results for tests that have finished running
        prevProps.runningTestIndexes.forEach(index => {
            if (!this.props.runningTestIndexes.includes(index)) {
                this.clearTypedResultForTest(index);
            }
        });
    }

    componentWillUnmount() {
        this.clearTypingTimeouts();
    }

    // Simulates typing effect for interim results
    private typeInterimResult = (testIndex: number, interactionIndex: number, newText: string, isCompleted: boolean = false) => {
        if (this.state.typingTimeouts[testIndex]?.[interactionIndex]) {
            clearTimeout(this.state.typingTimeouts[testIndex][interactionIndex]);
        }

        const currentTyped = this.state.typedInterimResults[testIndex]?.[interactionIndex] || '';
        const toType = newText.slice(currentTyped.length);

        let charIndex = 0;
        const typeNextChar = () => {
            if (charIndex < toType.length) {
                this.setState(prevState => {
                    const updatedResults = {
                        ...prevState.typedInterimResults,
                        [testIndex]: {
                            ...(prevState.typedInterimResults[testIndex] || []),
                            [interactionIndex]: currentTyped + toType.slice(0, charIndex + 1)
                        }
                    };
                    return { typedInterimResults: updatedResults };
                });

                charIndex++;
                const newTimeout = setTimeout(typeNextChar, isCompleted ? 15 : 30);
                this.setState(prevState => ({
                    typingTimeouts: {
                        ...prevState.typingTimeouts,
                        [testIndex]: {
                            ...(prevState.typingTimeouts[testIndex] || {}),
                            [interactionIndex]: newTimeout
                        }
                    }
                }));
            }
        };

        typeNextChar();
    };

    // Updates the state with completed test results
    private updateCompletedResults = () => {
        const { testResults, selectedTestIndex } = this.props;
        const currentTestResult = testResults[selectedTestIndex];
        if (currentTestResult) {
            currentTestResult.interactions.forEach((interaction: any, index: number) => {
                const completedItem = interaction.items.find((item: any) => item.status === TestResultStatus.COMPLETED);
                if (completedItem) {
                    this.typeInterimResult(selectedTestIndex, index, completedItem.actual, true);
                }
            });
        }
    };

    // Clears all typed interim results
    private clearTypedResults = () => {
        this.setState({
            typedInterimResults: {},
            typingTimeouts: {}
        });
        this.clearTypingTimeouts();
    };

    // Clears typed interim result for a specific test
    private clearTypedResultForTest = (testIndex: number) => {
        this.setState(prevState => ({
            typedInterimResults: {
                ...prevState.typedInterimResults,
                [testIndex]: [] as string[]
            },
            typingTimeouts: {
                ...prevState.typingTimeouts,
                [testIndex]: {}
            }
        }));

        if (this.state.typingTimeouts[testIndex]) {
            Object.values(this.state.typingTimeouts[testIndex]).forEach(clearTimeout);
        }
    };

    private clearTypingTimeouts = () => {
        Object.entries(this.state.typingTimeouts).forEach(([testIndex, testTimeouts]) => {
            Object.values(testTimeouts).forEach(clearTimeout);
        });
    };

    handleAddInteraction = () => {
        this.props.addInteraction(this.props.selectedTestIndex);
    }

    handleRemoveInteraction = (interactionIndex: number) => {
        this.props.removeInteraction(this.props.selectedTestIndex, interactionIndex);
    }
    handleInsertInteraction = (interactionIndex: number) => {
        this.props.addInteraction(this.props.selectedTestIndex, interactionIndex);
    }

    handleInputChange = (interactionIndex: any, value: string) => {
        const validValue = chain(value).split(/[\n\t ]/).map(w => trim(w)).join(' ').value()
        this.props.updateInteractionInput(this.props.selectedTestIndex, interactionIndex, validValue);
    }

    handleAddAssertion = (interactionIndex: any) => {
        this.props.addAssertion(this.props.selectedTestIndex, interactionIndex);
    }

    handleRemoveAssertion = (interactionIndex: number, itemIndex: number) => {
        this.props.deleteAssertion(this.props.selectedTestIndex, interactionIndex, itemIndex);
    }

    handleOperatorChange = (interactionIndex: any, itemIndex: any, value: string) => {
        this.props.updateAssertionOperator(this.props.selectedTestIndex, interactionIndex, itemIndex, value);
    }

    handleValueChange = (interactionIndex: any, itemIndex: any, value: string) => {
        const validValue = chain(value).split(/[\n\t ]/).map(w => trim(w)).join(' ').value()

        this.props.updateAssertionValue(this.props.selectedTestIndex, interactionIndex, itemIndex, validValue);
    }

    onSortStart = () => {
        // this.setState(prevState => ({
        //     ...prevState,
        //     grabbing: true,
        // }));
        // this.props.clearValidationResults();
    }

    onSortEnd = ({ oldIndex, newIndex }: any) => {
        // const {loadingValidationResults, yamlObject, selectedTestIndex} = this.props;
        // if (loadingValidationResults) return;
        // const moveYamlObject = {...yamlObject};
        // moveYamlObject.tests[selectedTestIndex].interactions = arrayMove(moveYamlObject.tests[selectedTestIndex].interactions, oldIndex, newIndex);
        // this.props.handleSetYamlObject(moveYamlObject);
        // this.setState(prevState => ({
        //     ...prevState,
        //     grabbing: false,
        // }));
    }

    handleInputBlur = (testIndex: any, utterIndex: any, event: any) => {
        let utterClass = { ...this.state.utterClass };
        if (!event.target.value) {
            utterClass[`${testIndex}${utterIndex}`] = validationVisualStyle.empty_expected || "empty_expected";
        } else {
            utterClass[`${testIndex}${utterIndex}`] = '';
        }
        this.setState(prevState => ({
            ...prevState,
            utterClass,
        }));
    }

    interactions = () => {
        const selectedIndex = this.props.selectedTestIndex;
        return this.props.source?.yamlObject?.tests.length &&
            this.props.source?.yamlObject?.tests[selectedIndex] &&
            this.props.source?.yamlObject?.tests[selectedIndex]?.interactions;
    }

    testResults = (testIndex: any, interactionIndex: any): any => {
        return this.props.testResults?.length &&
            this.props.testResults[testIndex] &&
            this.props.testResults[testIndex].interactions?.length &&
            this.props.testResults[testIndex].interactions[interactionIndex];
    }

    // Renders the actual result (either interim or final)
    renderTestResultItem = (item: any, index: number, interactionIndex: number, expectedInput: string) => {
        const status = item.status;
        const isCurrentTestRunning = this.props.runningTestIndexes.includes(this.props.selectedTestIndex);
        const pendingResult = status === TestResultStatus.PENDING && isCurrentTestRunning;

        const valueIsNull = isUndefined(item?.actual) || isNull(item?.actual);
        const typedInterimResult = this.state.typedInterimResults[this.props.selectedTestIndex]?.[interactionIndex];

        // Render completed or pending results
        if (status === TestResultStatus.COMPLETED || pendingResult) {
            return this.renderTypedResult(item, index, interactionIndex, expectedInput, typedInterimResult);
        }

        // Only show typed interim results for the current running test
        if (this.props.selectedTestIndex === this.props.runningTestIndexes[0] && typedInterimResult) {
            return this.renderTypedResult(item, index, interactionIndex, expectedInput, typedInterimResult);
        }

        return <div key={`actual_div${interactionIndex}${index}`} />;
    }

    // Renders the typed result with appropriate styling
    renderTypedResult = (item: any, index: number, interactionIndex: number, expectedInput: string, typedValue: string) => {
        const value = typedValue || item.actual;
        const passed = item.passed;
        const error = passed ? '' : item.actual;
        const valueIsNull = isUndefined(item?.actual) || isNull(item?.actual);

        return !valueIsNull ? (
            <TooltipDiv
                key={`actual-tooltip-${interactionIndex}-${index}`}
                tooltipPosition={'vertical'}
                tooltip={value}
                theme={bespokenTooltipTheme}
            >
                <MatchViewer
                    type={item.status === TestResultStatus.COMPLETED ? (passed ? "successful" : "failed") : "interim"}
                    before={expectedInput}
                    after={value}
                />
            </TooltipDiv>
        ) : (error &&
            <TooltipDiv
                key={`actual_div${interactionIndex}${index}`}
                data-react-toolbox="input"
                tooltip={error}
                theme={bespokenTooltipTheme}>
                <MatchViewer
                    type={"failed"}
                    before={error}
                    after={error}
                />
            </TooltipDiv>
        );
    }

    renderInteractionItemRow = (item: any, index: any, interactionIndex: any, rowsLength: any) => {
        const renderClearButton = (index !== 0 || (index === 0 && rowsLength > 1));
        const emptyClass = this.state.utterClass[`${interactionIndex}${index}`];

        return [
            renderClearButton
                ? (
                    <span
                        key={`remove-assertion-button-${interactionIndex}`}
                        title={"Remove assertion"}
                        className={Styles.remove_expected}
                    >
                        <RemoveIconButton
                            onClick={this.handleRemoveAssertion.bind(this, interactionIndex, index)}
                        />
                    </span>
                )
                : undefined
            ,
            <PropertyDropdown
                key={`prompt-selector-${interactionIndex}-${index}`}
                onChange={val => {
                    this.props?.updateAssertionAction(
                        this.props?.selectedTestIndex,
                        interactionIndex,
                        index,
                        val.value
                    );
                }}
                platform={this.props?.source?.config?.platform}
                value={item?.action}
                customValue={item?.action}
            />
            ,
            <Dropdown
                key={`prompt-operator-${interactionIndex}-${index}-${uniqueId()}`}
                className={Styles.operator}
                onChange={this.handleOperatorChange.bind(this, interactionIndex, index)}
                source={OPERATORS}
                value={item.operator}
                auto={false}
            />,

            <Input
                key={`prompt-expected-${interactionIndex}-${index}}`}
                multiline={true}
                className={`${Styles.expected} ${emptyClass} text_input`}
                error={isEmpty(item?.value || item?.expected || "") ? 'Expected is required' : false}
                value={item.value || item.expected || ""}
                hint={"Expected value"}
                onChange={this.handleValueChange.bind(this, interactionIndex, index)}
                onBlur={this.handleInputBlur.bind(this, interactionIndex, index)}
            />
        ]
    }

    // Renders a single interaction row
    renderInteractionRow = (row: any, interactionIndex: any, rowsLength: any) => {
        const testIndex = this.props.selectedTestIndex;
        let interimResult: (string | undefined) = undefined
        // Update to get interim results for specific test
        if (this.props.interimResults?.[testIndex] && interactionIndex < this.props.interimResults[testIndex].length) {
            interimResult = this.props.interimResults[testIndex][interactionIndex];
        }
        const interactionResult = this.testResults(this.props.selectedTestIndex, interactionIndex);
        const areResultsCompleted = interactionResult?.items?.length > 0 && interactionResult?.items?.every((item: any) => item.status === TestResultStatus.COMPLETED);
        const isResultOk = areResultsCompleted && interactionResult?.items.every((assert: any) => assert.passed);
        const failures = areResultsCompleted && interactionResult?.items.filter((assert: any) => !assert.passed).length;
        const borderClass = areResultsCompleted && (isResultOk ? validationVisualStyle.success : validationVisualStyle.error);
        const interactionBorderColor = areResultsCompleted && (isResultOk ? Styles.border_color_success : Styles.border_color_error);

        const sizeClass = areResultsCompleted && (interactionResult?.items?.length > 1 ? validationVisualStyle.small : validationVisualStyle.large);

        const renderClearButton = (interactionIndex !== 0 || (interactionIndex === 0 && rowsLength > 1));
        const emptyClass = this.state.utterClass[`${interactionIndex}`];
        const expectedList = row.items;
        const sourceType = this.props.source?.config?.platform;
        const inputDisabled = sourceType === "phone" && interactionIndex === 0 && row?.input === "$DIAL";
        const isCurrentTestRunning = this.props.runningTestIndexes.includes(this.props.selectedTestIndex);

        // Update to get typed interim result for specific test and interaction
        const typedInterimResult = this.state.typedInterimResults[testIndex]?.[interactionIndex];
        let isInterimResultDisplayed = false;

        return (
            <div className={Styles.step_row} key={`parent_div${interactionIndex}`}>
                <div className={Styles.side_buttons}>
                    {
                        renderClearButton &&
                        (
                            <span title={"Remove interaction"}>
                                <IconButton icon={"clear"}
                                    className={Styles.remove_row}
                                    onClick={this.handleRemoveInteraction.bind(this, interactionIndex)}
                                />
                            </span>
                        )
                    }
                    {
                        renderClearButton &&
                        (
                            <span title={"Insert interaction"}>
                                <IconButton icon={"add"}
                                    className={Styles.add_row_here}
                                    onClick={this.handleInsertInteraction.bind(this, interactionIndex)}
                                />
                            </span>
                        )
                    }
                </div>
                <div className={cn(Styles.interaction_row, interactionBorderColor)} key={`interaction-row-${interactionIndex}`}>
                    <div className={Styles.interaction_input}>
                        <Input
                            multiline={true}
                            name={`input${interactionIndex}`}
                            error={isEmpty(row?.input) ? 'Input is required' : false}
                            key={`input${interactionIndex}`}
                            value={row.input}
                            hint={"You say to the device ..."}
                            data-id="utter-input-interaction"
                            className={cn(emptyClass, 'text_input', Styles.multiline_text_input)}
                            onChange={this.handleInputChange.bind(this, interactionIndex)}
                            onBlur={this.handleInputBlur.bind(this, interactionIndex, "")}
                            disabled={inputDisabled}
                        />
                    </div>
                    <div className={Styles.expected_and_actual}>

                        {
                            chain(expectedList)
                                .map((item: any, expectedRowIndex: number) => {
                                    return map(this.renderInteractionItemRow(item, expectedRowIndex, interactionIndex, expectedList.length), (e, col) => {
                                        if (col === 0) { return <div key={`expected-r${expectedRowIndex}-c${col}`} className={Styles.prompt_remove_button}>{e}</div> }
                                        if (col === 1) { return <div key={`expected-r${expectedRowIndex}-c${col}`} className={Styles.prompt_input}>{e}</div> }
                                        if (col === 2) { return <div key={`expected-r${expectedRowIndex}-c${col}`} className={Styles.prompt_colon}>{e}</div> }
                                        if (col === 3) { return <div key={`expected-r${expectedRowIndex}-c${col}`} className={Styles.prompt_expected}>{e}</div> }
                                    })
                                })
                                .map((elementRow, index) => index === 0 ? elementRow.concat(<div className={Styles.prompt_add_button}> <PlusSignIconButton onClick={this.handleAddAssertion.bind(this, interactionIndex)} title={"Add assertion"} /> </div>) : elementRow)
                                .map((elementRow, responseIndex) => {
                                    if (isEmpty(interactionResult?.items)) { return elementRow }
                                    if (isEmpty(interactionResult?.items[responseIndex])) { return elementRow }

                                    const expectedItem = expectedList[responseIndex]
                                    const expectedInput = expectedItem?.value || expectedItem?.expected || ""

                                    let actualResponses;
                                    // Display interim result if available and not already displayed
                                    if (!interactionResult?.items[responseIndex] || (!typedInterimResult && interactionResult?.items[responseIndex].status === TestResultStatus.PENDING)) {
                                        actualResponses = (
                                            <div key={`actual_div${interactionIndex}${responseIndex}`} className={Styles.loading}>
                                                <IconButton primary={true} icon={<SpinnerImg />} />
                                            </div>
                                        );
                                    } else if (!isInterimResultDisplayed && interactionResult?.items[responseIndex].path === 'prompt' &&
                                        typedInterimResult && interactionResult?.items[responseIndex].status === TestResultStatus.PENDING) {
                                        actualResponses = (
                                            <TooltipDiv
                                                key={`actual-tooltip-${testIndex}-${interactionIndex}-${responseIndex}`}
                                                tooltipPosition={'vertical'}
                                                tooltip={typedInterimResult}
                                                theme={bespokenTooltipTheme}
                                            >
                                                <MatchViewer
                                                    type="interim"
                                                    before={typedInterimResult}
                                                    after={typedInterimResult}
                                                />
                                            </TooltipDiv>
                                        );
                                        isInterimResultDisplayed = true;
                                    } else {
                                        // Render final or other interim results
                                        actualResponses = this.renderTestResultItem(interactionResult?.items[responseIndex], responseIndex, interactionIndex, expectedInput);
                                    }

                                    return elementRow.concat(<div key={`actual-${responseIndex}`} className={Styles.actual_column}>{actualResponses}</div>)
                                })
                                .value()

                        }
                    </div>
                    <div className={`${Styles.resume}`}>
                        {areResultsCompleted && (
                            isResultOk ? (
                                <SuccessBadgeSmall>
                                    {interactionResult?.items?.length || ""} Assertion{interactionResult?.items?.length === 1 ? "" : "s"} | &nbsp;
                                    {!failures ? "No Failures" : `${failures} Failure${failures === 1 ? "" : "s"}`}
                                </SuccessBadgeSmall>
                            ) : (
                                <ErrorBadgeSmall>
                                    {interactionResult?.items?.length || ""} Assertion{interactionResult?.items?.length === 1 ? "" : "s"} | &nbsp;
                                    {!failures ? "No Failures" : `${failures} Failure${failures === 1 ? "" : "s"}`}
                                </ErrorBadgeSmall>
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const interactions = this.interactions();
        const isAnyTestRunning = this.props.runningTestIndexes.length > 0;

        return (
            <div className={Styles.main_container}>
                <div className={cn(validationVisualStyle.container, Styles.container)} data-intercom-target="TestEditor">
                    <div className={validationVisualStyle.title_container}>
                        <div>
                            <h4>
                                Input
                            </h4>
                            <small>
                                (Your question to the device)
                            </small>
                        </div>
                        <div className={`${validationVisualStyle.title} ${validationVisualStyle.second_item}`}>
                            <h4>
                                Expected
                            </h4>
                            <small>
                                (Your expected answer from the device)
                            </small>
                        </div>
                        <div className={`${validationVisualStyle.title} ${validationVisualStyle.third_item}`}>
                            <h4>
                                Actual
                            </h4>
                            <small>
                                (The actual device response)
                            </small>
                        </div>
                    </div>
                    <SortableParentContainer
                        onSortStart={this.onSortStart}
                        onSortEnd={this.onSortEnd}
                        useDragHandle={true}
                        disabled={isAnyTestRunning}
                    >
                        {
                            interactions && interactions.length > 0
                            && interactions?.map((row: any, index: number) => (
                                <SortableItem
                                    key={`interaction-${index}`}
                                    index={index}
                                    grabbing={this.state.grabbing}
                                    itemCount={interactions.length}
                                    itemToRender={this.renderInteractionRow(row, index, interactions.length)} />
                            ))
                        }
                    </SortableParentContainer>
                    <div className={Styles.add_button_container} data-intercom-target="AddInteraction">
                        <IconLabelButton
                            icon={<BespokenAddIcon />}
                            label={"Add Interaction"}
                            disable={isAnyTestRunning}
                            onClick={this.handleAddInteraction}
                            size="small"
                        />
                    </div>
                </div>
                {this.props.children}
            </div>
        )
    }
}