// src/lib/utils/cookies.ts

// Helper to determine if we're in local development
const isLocalhost = (): boolean => {
    return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
};

// Get the appropriate cookie domain
const getCookieDomain = (): string => {
    return isLocalhost() ? '' : '.bespoken.io';
};

// Set a cookie that's accessible across subdomains
export const setCrossDomainCookie = (name: string, value: string, daysToExpire: number = 30) => {
    const date = new Date();
    date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));

    const cookieDomain = getCookieDomain();

    const cookieParts = [
        `${name}=${value}`,
        `expires=${date.toUTCString()}`,
        'path=/',
        'SameSite=Lax',
        'Secure'
    ];

    // Only add domain attribute if not on localhost
    if (cookieDomain) {
        cookieParts.push(`domain=${cookieDomain}`);
    }

    document.cookie = cookieParts.join('; ');
};

// Get a cookie by name (no changes needed)
export const getCookie = (name: string): string | null => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : null;
};

// Remove a cookie
export const removeCrossDomainCookie = (name: string) => {
    const cookieDomain = getCookieDomain();
    const cookieParts = [
        `${name}=`,
        'path=/',
        'expires=Thu, 01 Jan 1970 00:00:01 GMT'
    ];

    if (cookieDomain) {
        cookieParts.push(`domain=${cookieDomain}`);
    }

    document.cookie = cookieParts.join('; ');
};